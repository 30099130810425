.columncontainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px 0;
    h1 {
        width: 100%;
    }
    .left-column {
        width: 100%;
        @media only screen and (min-width: 576px) {
            width: 70%;
        }
    }
    .columncontainer__sidebar {
        background: white;
        padding: 20px;
        width: 100%;
        margin-top: 30px;
        @media only screen and (min-width: 576px) {
            width: 28%;
            margin-top: 0px;
        }
        .sidebar__button {
            margin-bottom: 30px;
            &:hover {
                background-color: black;
            }
        }
        .sidebar__list {
            padding-left: 20px;
            line-height: 1.5;
            margin-top: -12px;
            .sidebar__list-link {
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: black;
                letter-spacing: 0;
                text-transform: none;
            }
        }
    }
}