.page-background {
    background-image: url(../img/background.jpg);
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-attachment: fixed;
//    min-height: 100vh;
    padding-bottom: 50px;
    padding-top: 50px;
}
