.footer {
    background: #396C7C;
    padding: 10px 0;
    .footer__container {
        @include make-container();
        @include make-container-max-widths(); 
        @media only screen and (min-width: 992px) {
            max-width: unset;
        }
        .footer__items {
            padding: 0;
            margin: 0;
            text-align: center;
            list-style-type: none;
            @media only screen and (min-width: 576px) {
                text-align: left;
            }
            @media only screen and (min-width: 992px) {
                text-align: center;
            }
            .footer__item {
                margin: 10px 0;
                @media only screen and (min-width: 992px) {
                    display: inline-block;
                    margin: 0;
                }
                .footer__item-link {
                    color: white;
                    padding: 10px;
                    &:hover {
                        color: black;
                    }
                }
            }
        }
    } 
}