.header {
    width: 100%;
    .top-bar {
        background-color: black;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-bar__logo {
            height: 28px;
            margin: 11px 10px;
        }
        .top-bar__hamburger {
            width: 30px;
            height: 30px;
            right: 10px;
            top: 10px;
            position: absolute;
            cursor: pointer;
            @media only screen and (min-width: 576px) {
                display: none;
            }
        }
        .top-bar__nav .top-bar__nav-elements {
            margin: 0;
            width: 100%;
            position: absolute;
            top: 50px;
            left: 0;
            background-color: black;
            padding: 20px 5px;
            display: none;
            @media only screen and (min-width: 576px) {
                display: block;
                background: none;
                top:0;
                padding: 0;
                position: relative;
            }
            .top-bar__nav-element {
                display: inline-block;
                width: 100%;
                @media only screen and (min-width: 576px) {
                    display: inline;
                }
                .top-bar__nav-element-link {
                    width: 100%;
                    display: inline-block;
                    padding: 10px 10px;
                    font-size: 18px;
                    color: white;
                    @media only screen and (min-width: 576px) {
                        width: auto;
                        display: inline-block;
                        font-size: 14px;
                        border-bottom: 1px solid black;
                        &:hover {
                            border-bottom: 1px solid white;
                        }
                    }
                }
            }
        }
    }
    .search-bar {
        background-color: #396C7C;
        padding: 14px 0;
        .search-bar__inner-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            @include make-container();
            @include make-container-max-widths();
        }
        .search-bar__letters {
            text-align: center;
            .search-bar__letter {
                color: white;
                padding: 6px 5px;
                font-size: 16pt;
                display: inline-block;
                text-transform: capitalize;
                @media only screen and (min-width: 576px) {
                    padding: 4px;
                    font-size: 14pt;
                }
                &:hover {
                    color: black;
                }
            }
        }
        .search-bar__search-form {
            width: 100%;
            .search-bar__sarch-field {
                width: 100%;
                height: 35px;
                font-size: 16pt;
                padding: 2px 5px;
                margin-top: 7px;
                border-radius: 0;
                @media only screen and (min-width: 576px) {
                    width: 100%;
                    font-size: 14pt;
                    height: 25px;
                }
            }
        }
    }
}
