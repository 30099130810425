.button {
    width: 100%;
    display: block;
    padding: 15px 0 11px;
    text-align: center;
    background-color: #396C7C;
    color: white;
    transition: all 0.2s ease;
    &:hover {
        text-decoration: none;
        background-color: black;
        color: white;
    }
}