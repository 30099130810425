.def-container {
    padding: 16px 10px 12px;
    margin-bottom: 20px;
    background: white;
    .def-container__link {
        text-transform: none;
        color: black;
        font-size: 16px;
        font-weight: 400;
    }
    .def-container__title {
        color: #396C7C;   
        margin-top: 0;
        margin-bottom: 0;
        &:hover {
            color: black;
        }
    }
    .def-container__footer {
        font-style: italic;  
        .def-container__author {
            display: inline;
            padding-left: 1px;
        } 
        .def-container__report {
            text-transform: uppercase;
            font-weight: 600;
            font-style: normal;
            font-size: 14px;
            color: #5d5d5d;
            font-family: 'Open Sans', sans-serif;
            &:hover {
                color: black;
            }
        }
    }
}
