@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./layouts/header";
@import "./layouts/footer";
@import './layouts/grid';
@import './global/buttons';
@import './global/forms';
@import './global/fonts';
@import './partials/def-container';
@import './partials/add-form';
@import './partials/page-background';
@import './partials/columncontainer';


.tag-cloud {
  text-align: center;
  word-spacing: 1rem;
}

.marker {
  background-color: #FFFF80;
}

.jscroll-added {
  margin-bottom: 20px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll;
}

main {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

footer {
  flex: 0 0 auto;
}
