.add-form {
    background-color: white;
    margin-top:50px;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    color: black;
    .add-form__title {
        //margin-bottom: -10px;
        color: black;
    }
    .add-form__label {
        display: block;
        margin-bottom: 3px;
        text-transform: uppercase;
        //margin-top: 35px;
    }
    .add-form__input {
        width: 100%;
        height: 35px;
        border: 1px solid grey;
        padding-left: 5px;
        border-radius: 0;
        @media only screen and (min-width: 576px) {
            max-width: 600px;
            height: 30px;
        }
    }
    textarea {
        width: 100%;
        border: 1px solid grey;
        padding-left: 5px;
        border-radius: 0;
        @media only screen and (min-width: 576px) {
            max-width: 600px;
        }
    }
    .checkboxcontainer {
        display: flex;
        max-width: 600px;
        .checkboxbox {
            position: relative;
            input[type=checkbox] {
                opacity: 0;
                width:30px;
            }
            label {
                cursor: pointer;
                position: absolute;
                width: 25px;
                height: 25px;
                top: 0;
                left: 0;
                background: #eee;
                border:1px solid #ddd;
                margin-top: 0;
                &:after {
                    opacity: 0.2;
                    content: '';
                    position: absolute;
                    width: 9px;
                    height: 5px;
                    background: transparent;
                    top: 8px;
                    left: 7px;
                    border: 3px solid #333;
                    border-top: none;
                    border-right: none;
                    transform: rotate(-45deg);
                }
                &:hover::after {
                    opacity: 0.5;
                }
            }
            &:focus-within label {
                background: Highlight;
            }
            input[type=checkbox]:checked + label:after {
                opacity: 1;
            }
        }
        .checkboxtext {
            font-style: italic;
            font-size: 14px;
            color: rgb(49, 49, 49);
            margin-left: 5px;
            margin-top: -5px;

            label {
                font-weight: 400;
                font-family: Open Sans,sans-serif;
                cursor: pointer;
            }
        }
    }
    .instruction {
        font-style: italic;
        font-size: 14px;
        color: rgb(49, 49, 49);
        margin-top: 7px;
        max-width: 600px;
    }
    .button {
        width: auto;
        padding: 15px 30px 11px;
        background-color: #396C7C;
        color: white;
        //margin-top: 60px;
        margin-bottom: 20px;
        @media only screen and (min-width: 576px) {
            display: inline-block;
        }
        &:hover {
            background-color: black;
            color: white;
        }
    }
    p {
        font-size: 14px;
    }
}
