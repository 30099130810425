body, html {
    margin: 0;
}

h1, h3, label {
    font-family: 'Proza Libre', sans-serif;
}

h1 {
    font-size: 30px;
    color:white;
    margin-top: 0;
}

h3 {
    text-transform: uppercase;
    font-size: 18px;
}

p, ul, ol, span {
    font-family: 'Open Sans', sans-serif;
}

a {
    font-family: 'Proza Libre', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
        text-decoration: none;
    }
}