* {
    box-sizing: border-box;
}

.main-inner {
    @include make-container();
    @include make-container-max-widths();
}

.main {
    @include make-container();
}